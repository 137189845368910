*{
  margin:0;
  padding:0;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F2F2;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Content{
  min-height: 100vh;
}


h1{
  padding-bottom: 10px;
  font-style: normal;
  font-weight: normal;
}

h2{
  padding-bottom: 10px;
  font-style: normal;
  font-weight: normal;
}

h3{
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
}

a{
  text-decoration: none;
  color:black;
}

a:hover{
  text-decoration: underline;
}

header{
  width: 100%;
}

.HeaderItemLeft img{
  width: 254px;
}

.HeaderItems{
  display: flex;
  
}

.HeaderItemLeft{
  position: relative;
  margin: auto;
  margin-right: 40px;
}

.HeaderItemMiddle{
  width: 500px;
  border: 1px solid #2D76CE;
  border-top: none;
}

.HeaderItemMiddle img{
  margin: 7px;
  width: 45px;
}

nav{
  display: flex;
  height: 100%;
}

nav a{
  text-align: center;
  font-size: 14px;
  flex-grow: 1;
  opacity: 0.5;
  border-right: 1px solid #cccccc;

  background-size: 100% 201%;
  background-image: linear-gradient(to bottom, transparent 50%, #64A4F2 50%);
  -webkit-transition: background-position 0.5s;
  -moz-transition: background-position 0.5s;
  transition: background-position 0.5s;
}

.NavActive{
  Opacity: 1;
  background: #64A4F2;
}

nav a:hover{
  background-position: 0 -100%;
  text-decoration: none;
}

nav a:last-child {
  border-right: none;
}

.HeaderItemRight{
  display: flex;
  position: relative;
  margin: auto;
  opacity: 0.5;
  margin-left: 25px;
  width: 250px;
}

.HeaderItemRight h3{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

footer{
  background: #E6E6E6;
  position: relative;
  width: 100%;
}

footer img{
  height: 90px;
  width: 70px;
}

.FooterItems{
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 980px;
}

.FooterHome{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 60px;
  margin-right: 60px;
}

.FooterItem{
  flex-grow: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.InnerFooterItem p{
  margin-top: 5px;
  line-height: 20px;
}

.InnerFooterItem{
  display:inline-block;
  text-align: left;
}

.BannerBlockText{
  position:absolute;
  left: 20%; 
  top: 200px;
  color: #2D76CE  ;

}

.Banner{
  width: 100%;
}

.FloatingBlocks{
  display: flex;
  width:800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -125px;
  color: #F2F2F2;
}

.Block{
  padding: 20px;
}

.BlockLeft{
  background: #2160AB;
  flex-grow: 1;
}

.BlockMiddle{
  background: #2D76CE;
  flex-grow: 1;
}

.BlockRight{
  background: #134076;
  flex-grow: 1;
}

.TextBlock{
  height: 150;
  margin-top: 50px;
}

.TextBlockContent{
  max-width: 1000px;
  margin-left:auto;
  margin-right: auto;
  background: #DCDCDC;
  border-radius: 5px;
  box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;

}

.TextBlockContent h1{
  color: #747474;
}

.StaffBlocks{
  margin-top: 50px;
  margin-bottom: 300px;
  max-width: 1000px;
  margin-left:auto;
  margin-right: auto;
}

.StaffBlocks h1{
  color: #747474;
}

.StaffBlocksContent{
  display: flex;
  margin-top: 10px;
}

.StaffBlocksContent .StaffBlock:first-child{
  margin-left:0px;
}

.StaffBlock{
  padding: 20px;
  background: #DCDCDC;
  border-radius: 5px;
  flex-grow: 1;
  margin-left: 25px;
  box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  width: 33%;
}

.StaffBlock img{
  width: 100%;
  margin-bottom: 20px;
}

.TreatmentsContent{
  max-width: 1000px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  display: grid;
  grid-template-areas:
    'menu info info'
    'menu info info'
    'picture picture picture';
  grid-gap: 20px;
}

.TreatmentsBlock{
  width: 350px;
  height: 455px;
  border-radius: 5px;
  box-shadow: 2px 4px 5px 4px rgba(0, 0, 0, 0.25);
  padding-top: 15px;
  grid-area: menu;
}

.TreatmentsBlockHeader{
  display:flex;
  width: 85%;
  height: 50px;
  color: #FCFCFC;
  background: #2160AB;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.TreatmentsBlockHeader img{
  margin: auto;
  margin-right: 20px;
  height: 35px;
  width: auto;
}

.TreatmentsBlockHeader h1{
  margin: auto;
  margin-left: 20px;
  padding-bottom: 0px;
  font-size: 160%;
}

.TreatmentList hr{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.Treatment{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  width: 85%;
}

.Treatment h3{
  margin: 10px;
  margin-left: 15px;
  margin-right: 0px;
  max-width: 75%;
}

.Treatment img{
  height: 30px;
  width: auto;
  margin: auto;
  margin-right: 0px;
}

.Treatment:hover{
  cursor: pointer;
}

.TreatmentsBlockImage{
  grid-area: picture;
  height: 250px;
  margin-top: 0px;
  margin-bottom: 24px;
  margin-left: 40%;
}

.TreatmentsInfo{
  grid-area: info;
  width: 600px;
  text-align: center;
}

.legend span{
  font-size: 150%;
}

.legend{
  font-size: 125% !important;
}

.carousel{
  min-height: 300px;
}

.carousel .slide{
  background:transparent !important;
  margin-top: 95px !important;
}

.carousel .slide .legend{
  background:transparent !important;
  color: #000000 !important; 
  opacity: 100 !important;
  text-align: left !important;
}

.HeightSetter{
  min-height: 250px;
}

.priceContent{
  display: flex;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.priceInfoBlock{
  width: 65%;
  margin-bottom: 250px;
}

.PriceBlock{
  width: 250px;
  height: 300px;
  border-radius: 5px;
  box-shadow: 2px 4px 5px 4px rgba(0, 0, 0, 0.25);
  padding-top: 15px;
  margin-top: 150px;
  margin-left: 75px;
}


.PriceBlockHeader{
  display:flex;
  width: 85%;
  height: 50px;
  color: #FCFCFC;
  background: #2160AB;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.PriceBlockHeader img{
  margin: auto;
  margin-right: 20px;
  height: 35px;
  width: auto;
}

.PriceBlockHeader h2{
  margin: auto;
  margin-left: 20px;
  padding-bottom: 0px;
  font-size: 140%;
}

.PriceList hr{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.CertificatesContent{
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 240px;
}

.CertificatesContent .TextBlock{
  width: 100%;
}

.CertificateCard{
  margin-top: 30px;
  width: 320px;
  height: 375px;
  text-align: center;
  border-radius: 7px;
  box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  background: #DCDCDC;
  margin-left: auto;
  margin-right: auto;
}

.CertificateCard h2{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;
}

.CertificateCard p{
  margin-top: 5px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.CertificateCard img{
  width: 60%;
}

.ContactContent{
  max-width: 1000px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.ContactContent h1{
  color: #747474
}

.ContactContent img{
  width: 100%;
}

.ContactInfo{
  margin-top: 20px;
  display: flex;
}

.ContactInfo p{
  width: 50%;
  font-size: 110%;
}

.ContactBanner{
  width: 100%;
  max-height: 150px;
}

.ContactMap{
  width: 50% !important;
  height: auto;
  margin-bottom: 220px;;
}

.DisclaimerContent{
  max-width: 1000px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  min-height: 850px;
}



.DisclaimerContent h2{
  color: #747474
}

.DisclaimerContent h3{
  color: #747474
}

@media only screen and (max-width: 1020px) {
  .HeaderItems{
    flex-wrap: wrap;
  }

  .HeaderItemRight{
    width: 30%;
    order: 0;
    margin-left: auto;
    margin-right: 0;
  }

  .HeaderItemMiddle{
    width: 100%;
    order: 1;
    border-top: 1px solid #2D76CE;
  }

  .HeaderItemMiddle img{
    width: 32px;
    height: auto;
  }

  nav a{
    -webkit-transition: background-position 0s;
    -moz-transition: background-position 0s;
    transition: background-position 0s;
  }

  .HeaderItemLeft{
    width: 50%;
    margin: auto;
  }

  .HeaderItemLeft img{
    width: 80%;
    height: auto;

  } 

  .FooterItems{
    width: auto;
  }

  .FooterHome{
    margin-right: 0px;
  }

  .BannerBlockText{
    top: 225px;
    left: 10%;
  }

  .FloatingBlocks{
    width: 100%;
    height: auto;
    margin-top: -35px;
  }
  .TextBlock, .StaffBlocks{
    margin-left: 20px;
    margin-right: 20px;
  }

  .TreatmentsContent{
    margin-top: 30px;
  }

  .TreatmentsBlock{
    margin-left: 30px;
    margin-bottom: 30px;
    width: 225px;
    height: 550px;
  }


  .TreatmentsBlockHeader{
    font-size: 70%;
  }

  .TreatmentsBlockHeader img{
    height: 30px;
    margin-right: 15px
  }

  .Treatment h3{
    font-size: 105%;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .Treatment img{
    height: 25px;
  }

  .TreatmentsBlockImage{
    max-width: 100%;
    height: 200px;
    margin-top: -50px;
  }

  .TreatmentsInfo{
    margin-top: 25px;
    width: 450px;
  }

  .carousel .slide .legend{
    padding: 0px !important;
  }

  .priceContent{
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .priceInfoBlock{
    width: 60%;
    margin-left: 10px;
    margin-bottom: 50px;
  }

  .TextBlock{
    margin-top: 15px;
  }

  .PriceBlock{
    margin-left: 40px;
    margin-top: 50px;
    margin-right: 50px;
    height: 275px;
  }

  .ContactContent{
    width: 90%;
  }

  .ContactMap{
    height: auto;
    margin-top: 10px;
    margin-bottom: 250px;
  }

  .DisclaimerContent{
    margin-bottom: 100px;
  }


}

@media only screen and (max-width: 736px) {

  .HeaderItems{
    flex-wrap: wrap;
  }

  .HeaderItemRight{
    width: 50%;
    order: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .HeaderItemRight img{
    display: none;
  }

  .HeaderItemMiddle{
    width: 100%;
    order: 1;
    border: none;
  }

  .HeaderItemMiddle img{
    width: 28px;
    height: auto;
  }

  nav a{
    -webkit-transition: background-position 0s;
    -moz-transition: background-position 0s;
    transition: background-position 0s;
  }

  .HeaderItemLeft{
    width: 50%;
    margin: 0;
  }

  .HeaderItemLeft img{
    width: 100%;
    height: auto;

  } 

  footer{
    margin-top: 0px;
  }

  .FooterItems{
    flex-wrap: wrap;
  }

  .FooterItem{
    width: 100%;
    border-bottom: 1px solid   #cccccc;
  }


  .FooterHome{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    order: 3;
  }

  .FooterItemMiddle{
    order: 0;
    width: 100%;
  }

  .FooterItemLeft{
    order: 1;
    width: 50%;
  }

  .FooterItemRight{
    order: 2;
    font-size: 12px ;
    width: 50%;

  }

  .InnerFooterItem p{
    line-height: 16px;;
  }

  footer h3 a{
    font-size: 18px;
    line-height: 22px;;
  }

  .Banner{
    Height: 150px;
  }

  .BannerBlockText{
    top: 22%;
    left: 5%;
  }

  .BannerBlockText h1{
    font-size: 130%;
    line-height: 18px;
    max-width: 60vw;
    padding-bottom: 0px;
  }

  .BannerBlockText h3 {
    font-size: 100%;
    line-height: 24px;
  }

  .FloatingBlocks{
    margin-top: -8px;
    flex-wrap: wrap;
    width: 90%;
    min-height: 500px;
    height: auto;
  }
  .TextBlock, .StaffBlocks{
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .TextBlock{
    margin-top: 20px;
    height: auto;
  }

  .StaffBlocks{
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .StaffBlocks h1{
    padding-bottom: 0px;
    margin-bottom: -15px;
  }

  .StaffBlocksContent{
    flex-wrap: wrap;
  }

  .StaffBlock{
    width: 100%;
    box-shadow: 1px 5px 4px rgba(0, 0, 0, 0.25);
    margin-left: 0px;
    margin-top: 20px;
  }

  .TreatmentsContent{
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
  }

  .HeightSetter{
    min-height: 225px;
  }

  .TreatmentsBlock{
    order: 0;
    width: 100%;
    height: auto;
    box-shadow: none;
    border-top: 1px solid black;
    border-radius: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .TreatmentsBlockHeader{
    margin: 0px;
    width:100%;
    border-radius: 0px;
  }

  .Treatment h3{
    font-size: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .TreatmentsBlockImage{
    order: 2;
    width: 80%;
    margin-bottom: 25px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .TreatmentList hr{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
  }

  .Treatment img{
    height: 25px;
  }

  .TreatmentsInfo{
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-top: 0px;
    margin-right: auto;
  }

  .carousel{
    min-height: auto;
  }

  .carousel .slide .legend{
    text-align: center !important;
    font-size: 105% !important;
    height: 100%;
    bottom: 0px !important; 
  }

  .HeigthSetter{
    min-height: 0px !important;
  }

  .priceContent{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .priceInfoBlock{
    width: 100%;
    margin-left: 10px;
    margin-bottom: 50px;
    order: 1;
  }

  .TextBlock{
    margin-top: 15px;
  }

  .PriceBlockHeader{
    width: 100%;
    margin-top: 0px;
    border-radius: 0px;;
  }

  .PriceBlock{
    border-radius: 0px;
    box-shadow: none;
    border-bottom: 1px solid black;;
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 0px;
    height: 140px;
    padding-top: 0px;
    order: 0;
  }
  
  .PriceList a{
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    margin-bottom: 3px;
    border-bottom: 1px solid black;
  }

  .carousel .slide{
    margin: 0px !important;
    min-height: 370px !important;
  }

  .PriceList{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .PriceList hr{
    display: none;
  }
  .PriceList img{
    display: none;
  }

  .CertificatesContent{
    margin-bottom: 30px;
  }

  .ContactContent{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .ContactInfo{
    margin-top: 20px;
    display: block;
  }

  .ContactInfo p{
    width: 100%;
  }

  .ContactMap{
    width: 100% !important;
    height: auto;
    margin-bottom: 10px;
  }

  .DisclaimerContent{
    width: 90%;
    margin-bottom: 20px;;
  }

}